import './social-feed.scss';

import { sendTracking } from '../../js/tracking';

class SocialFeedSlider {
    constructor (element, options) {
        const defaults = {
            trackingAttr: 'data-trackingcategory'
        };

        this.settings = Object.assign({}, defaults, options);

        this.$slider = element;
        this.trackingCategory = this.$slider.getAttribute(this.settings.trackingAttr);
        this.$slides = this.$slider.querySelectorAll('[data-socialfeed="slide"]');
        this.currentIndex = 0;
    }

    initialize () {
        const initSlider = (Slider) => {
            let clicked = false;
            const feedSlider = new Slider(this.$slider, {
                breakpoints: {
                    768: {
                        slidesPerView: 2,
                        slidesPerGroup: 1
                    },
                    1024: {
                        slidesPerView: 3,
                        slidesPerGroup: 1
                    }
                },
                speed: 500,
                indicators: true,
                prevnext: true,
                prevAriaLabel: this.$slider.dataset.slidePrevAriaLabel,
                nextAriaLabel: this.$slider.dataset.slideNextAriaLabel,
                paginationClickable: false,
                slidesPerView: 1,
                slidesPerGroup: 1,
                initAttr: 'data-socialfeed',
                onSlideChange: ($slider, slider, $currentSlide, currentIndex) => {
                    if (this.trackingCategory !== null) {
                        let direction;
                        if (currentIndex > this.currentIndex) {
                            direction = 'forth';
                        } else {
                            direction = 'back';
                        }

                        if (clicked === false) {
                            sendTracking({
                                category: this.trackingCategory,
                                action: 'swipe',
                                label: 'slide_' + direction + '_' + (currentIndex + 1)
                            });
                        }

                        this.currentIndex = currentIndex;
                        $currentSlide.focus();
                        clicked = false;
                    }
                },
                onClickPrev: (currentIndex) => {
                    clicked = true;

                    if (this.trackingCategory !== null) {
                        sendTracking({
                            category: this.trackingCategory,
                            action: 'click',
                            label: 'btn_back_' + (currentIndex + 1)
                        });
                    }
                },
                onClickNext: (currentIndex) => {
                    clicked = true;

                    if (this.trackingCategory !== null) {
                        sendTracking({
                            category: this.trackingCategory,
                            action: 'click',
                            label: 'btn_forth_' + (currentIndex + 1)
                        });
                    }
                }
            });

            this.$slider.API = feedSlider;

            this.$slides.forEach($slide => {
                $slide.addEventListener('keydown', (event) => {
                    if (event.code === 'Space' || event.code === 'Enter') {
                        $slide.classList.add('is--active');
                    }

                    if (event.code === 'Escape') {
                        $slide.classList.remove('is--active');
                    }
                });

                $slide.addEventListener('focus', (event) => {
                    this.$slides.forEach(s => {
                        if (s !== $slide) {
                            s.classList.remove('is--active');
                        }
                    });
                });
            });
        };

        import('../../components/slider/slider').then(({ Slider }) => {
            initSlider(Slider);
        });
    }
}

export { SocialFeedSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $socialfeedSliders = $context.querySelectorAll('[data-socialfeed="slider"]');

        if ($socialfeedSliders.length > 0) {
            for (let i = 0; i < $socialfeedSliders.length; i++) {
                const $socialfeedSlider = new SocialFeedSlider($socialfeedSliders[i]);
                $socialfeedSlider.initialize();
            }
        }
    }
});
